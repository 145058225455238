import React, {useEffect} from "react";
import useState from 'react-usestateref';
import {beep as beepNow, WORKER_TYPE} from "../helpers";
import {CODE_TYPE} from "../transformers/base";
import "../css/scan.css";
import InputMask from 'react-input-mask';

const BTN_TXT = {
  START: "SCAN",
  STOP: "STOP"
};

const CANVAS_SIZE = {
  WIDTH: 320,
  HEIGHT: 430
};

const CAPTURE_OPTIONS = {
  audio: false,
  video: {facingMode: "environment"}
}

const READ_STATE = {
  0: "READ",
  1: "UNREAD",
  2: "READING"
};

const OWNERSHIP_STATE = {
  0: "OWNED",
  1: "ORDERED",
  2: "WANTED"
};

const sw = CANVAS_SIZE.WIDTH;
const sh = CANVAS_SIZE.HEIGHT;
const dw = sw;
const dh = sh;
const dx = 0;
const dy = 0;
let sx = 0;
let sy = 0;

const crossHairSvg = "M77.125 148.02567c0-3.5774 2.73862-6.27567 6.37076-6.27567H119V117H84.0192C66.50812 117 52 130.77595 52 148.02567V183h25.125v-34.97433zM237.37338 117H202v24.75h35.18494c3.63161 0 6.69006 2.69775 6.69006 6.27567V183H269v-34.97433C269 130.77595 254.88446 117 237.37338 117zM243.875 285.4587c0 3.5774-2.73863 6.27567-6.37076 6.27567H202V317h35.50424C255.01532 317 269 302.70842 269 285.4587V251h-25.125v34.4587zM83.49576 291.73438c-3.63213 0-6.37076-2.69776-6.37076-6.27568V251H52v34.4587C52 302.70842 66.50812 317 84.0192 317H119v-25.26563H83.49576z";
const crossHairWidth = 217, crossHairHeight = 200, x0 = 53, y0 = 117;

export default function Scan({
  beep = true,
  decode = true,
  worker = WORKER_TYPE.WASM,
  scanRate = 250,
  bw = false,
  crosshair = true,
}) {

  // Component state
  const [btnText, setBtnText] = useState(BTN_TXT.START);
  const [scanning, setScanning] = useState(false);

  const [bwOn, setBwOn, bwRef] = useState(bw);
  const [crosshairOn, setCrosshairOn, crosshairRef] = useState(crosshair);

  const [transformToggle, setTransformToggle] = useState(true);
  const [rawCode, setRawCode] = useState();
  const [codeType, setCodeType] = useState();
  const [beepOn, setBeepOn] = useState(beep);

  const [video] = useState(document.createElement("video"));
  const [barcode, setBarcode] = useState();
  const [milliseconds, setMilliseconds] = useState();
  const [resultOpen, setResultOpen] = useState();

  const [readState, setReadState] = useState('UNREAD'); // Default to READ
  const [ownershipState, setOwnershipState] = useState('OWNED'); // Default to OWNED
  const [modalShow, setModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [firstLaunch, setFirstLaunch] = useState(true);
  const [viewToggle, setViewToggle] = useState(true); // State to toggle between views
  const [format, setFormat] = useState(false); // State to toggle between views
  const [enteredISBN, setEnteredISBN] = useState(""); // State to store entered ISBN
  const [addedBookTitle, setAddedBookTitle] = useState("");
  const [addedBookThumbnailLink, setAddedBookThumbnailLink] = useState("");

  // Constants
  let qrworker = null;
  let canvasElement = null;
  let canvas = null;
  let oldTime = 0;

  video.onplaying = () => {
    sx = (video.videoWidth - CANVAS_SIZE.WIDTH) / 2;
    sy = (video.videoHeight - CANVAS_SIZE.HEIGHT) / 2;
  };

  const initWorker = () => {
    qrworker = new Worker(worker + "Worker.js");
    qrworker.onmessage = async ev => {
      if (ev.data != null) {
        qrworker.terminate();
        const result = ev.data;
        await stopScan();

        let res = result.data;
        const millis = ev.data.ms;
        const rawCode = res;
        let codeType = CODE_TYPE.RAW;

        setBarcode(res);
        setResultOpen(true);
        setRawCode(rawCode);
        setCodeType(codeType);
        setMilliseconds(millis);
        if (beepOn) beepNow();
        sendDataToServer(res); // Send scanned barcode to the server

      }
    };
  };

  const startScan = async () => {
    initWorker();
    canvasElement = document.getElementById("canvas");
    canvas = canvasElement.getContext("2d", {willReadFrequently: true});

    setBtnText(BTN_TXT.STOP);
    setBarcode(null);
    setResultOpen(false);
    setTransformToggle(true);
    setRawCode(null);
    setCodeType(CODE_TYPE.RAW);

    try {
      video.srcObject = await navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS);
      video.setAttribute("playsinline", "true");
      await video.play();
      setScanning(true);

      requestAnimationFrame(tick);
    } catch (err) {
      stopScan().then();
      console.log("stopped by the user");
      alert(err);
    }
  };

  const stopScan = async () => {
    setScanning(false);
    setBtnText(BTN_TXT.START);
    await video.pause();
    if (video.srcObject) {
      video.srcObject.getVideoTracks().forEach(track => track.stop());
      video.srcObject = null;
    }
  };

  const tick = (time) => {
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      canvas.drawImage(video, sx, sy, sw, sh, dx, dy, dw, dh);

      if (bwRef.current) monochromize();
      if (crosshairRef.current) drawCrosshair();
      if (scanning) requestAnimationFrame(tick);
      if (decode) recogniseQRcode(time);
    }
    requestAnimationFrame(tick);
  };

  const sendDataToServer = async (barcode) => {
    const token = localStorage.getItem('accessToken');

    try {
      const response = await fetch("/library/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`

        },
        body: JSON.stringify({
          isbn: barcode,
          owner: localStorage.getItem('loggedInUsername'),
          readState: readState,
          ownershipState: ownershipState,
          timestamp: Math.floor(Date.now() / 1000),
          format
        })
      });   
      if (response.status == 200) {
        
        const content = await response.json();
        const imageLinks = JSON.parse(content.thumbnail)

        setAddedBookThumbnailLink(imageLinks.thumbnail);
        setAddedBookTitle(content.title)

        setFirstLaunch(false);
        setErrorMessage(null);
        setModalShow(true);
      }else{
        const errorR = await response.json();
        setFirstLaunch(false);
        console.log(errorR.error);
        setErrorMessage(errorR.error);
        setModalShow(true);
      }

    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  };

  // Handlers for dropdown menu changes
  const handleReadStateChange = (event) => {
    setReadState(event.target.value);
  };
  
  const handleOwnershipStateChange = (event) => {
    setOwnershipState(event.target.value);
  };
  
  const formatButtonHandler = (event) => {
    setFormat(!format);
  }
  // Render dropdown menus
  const renderDropdowns = () => {
    return (
      <div className="dropdowns">
        <select className='select-box' value={readState} onChange={handleReadStateChange}>
          {Object.entries(READ_STATE).map(([key, value]) => (
            <option key={key} value={value}>{READ_STATE[key]}</option>
          ))}
        </select>
        <select className="select-box" value={ownershipState} onChange={handleOwnershipStateChange}>
          {Object.entries(OWNERSHIP_STATE).map(([key, value]) => (
            <option key={key} value={value}>{OWNERSHIP_STATE[key]}</option>
          ))}
        </select>
        <button className='other-button' onClick={formatButtonHandler}>{format ? 'Digital' : 'Physical'}</button>
      </div>
    );
  };
  
  const monochromize = () => {
    let imgd = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
    let pix = imgd.data;
    for (let i = 0; i < pix.length; i += 4) {
      let gray = pix[i] * 0.3 + pix[i + 1] * 0.59 + pix[i + 2] * 0.11;
      pix[i] = gray;
      pix[i + 1] = gray;
      pix[i + 2] = gray;
    }
    canvas.putImageData(imgd, 0, 0);
  };

  const drawCrosshair = () => {
    canvas.fillStyle = "rgba(255,255,255,0.4)";
    const shape = new Path2D(crossHairSvg);
    canvas.fill(shape);
  };

  const recogniseQRcode = (time) => {
    if (time - oldTime > scanRate) {
      oldTime = time;
      let imageData;
      if (crosshairRef.current === true)
        imageData = canvas.getImageData(x0, y0, crossHairWidth, crossHairHeight);
      else
        imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
      qrworker.postMessage({width: imageData.width, height: imageData.height});
      qrworker.postMessage(imageData, [imageData.data.buffer]);
    }
  };

  const onBtnClickHandler = async (e) => {
    e.preventDefault();
    if (scanning) await stopScan(); else await startScan();
  };

  const onCrossHairClickHandler = async (e) => {
    e.preventDefault();
    setCrosshairOn(!crosshairOn);
  };

  const onBWClickHandler = async (e) => {
    e.preventDefault();
    setBwOn(!bwOn);
  };

  const onBeepClickHandler = async (e) => {
    e.preventDefault();
    setBeepOn(!beepOn);
  };

  const startStyle = () => {
    const style = {width: 64, textAlign: "center"};
    if (scanning) return {backgroundColor: "red", ...style};
    else return {backgroundColor: "", ...style};
  };

  const xHairStyle = () => {
    if (crosshairOn) return {backgroundColor: "green"};
    else return {backgroundColor: ""};
  };

  const bwStyle = () => {
    if (bwOn) return {backgroundColor: "green"};
    else return {backgroundColor: ""};
  };

  const beepStyle = () => {
    if (beepOn) return {backgroundColor: "green"};
    else return {backgroundColor: ""};
  };

  const transformToggleStyle = () => {
    if (transformToggle) return {backgroundColor: "green", padding: 12};
    else return {backgroundColor: "", padding: 12};
  }

  useEffect(() => {}, []);

  const renderCanvas = () => {
    return <canvas id="canvas" className="scanCanvas" width={CANVAS_SIZE.WIDTH} height={CANVAS_SIZE.HEIGHT} />
  };

  const renderButtons = () => {
    return <div className="scanBtn">
      <a href="!#" className="myHref" onClick={onBtnClickHandler} style={startStyle()}>{btnText}</a>
    </div>;
  };

  const handleModalExit = () => {
    setModalShow(!modalShow);
  }

  const renderScan = () => {
    return (
      <div className="scan">
        {!firstLaunch && errorMessage && modalShow &&
          <div className="book-details-modal">
            <div className="modal-content">
              <h2 className="model-h2"><span style={{ color: 'red' }}>Error</span></h2>
              <div className="added-book-thumbnail">{errorMessage}</div>
              <div className="edit-modal-buttons">
                <button className="search-button-6" onClick={handleModalExit}>Close</button>
              </div>
            </div>
          </div>
        }

        {!firstLaunch && !errorMessage && modalShow &&
          <div className="book-details-modal">
            <div className="modal-content">
              <h2 className="model-h2">Added <span style={{ color: 'green' }}>{addedBookTitle}</span></h2>
              <img className="added-book-thumbnail" src={addedBookThumbnailLink} alt={addedBookTitle} />
              <div className="edit-modal-buttons">
                <button className="search-button-6" onClick={handleModalExit}>Close</button>
              </div>
            </div>
          </div>
        }

        {renderCanvas()}
        {renderDropdowns()}
        {renderButtons()}
        <button className="search-button" onClick={toggleView}>Switch to Enter ISBN</button>

      </div>
    );
  };

  const renderAddView = () => {
    const handleInputChange = (e) => {
      setEnteredISBN(e.target.value);
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      // Send enteredISBN to the server
      sendDataToServer(enteredISBN);
      setEnteredISBN(""); // Clear input after submission
    };

    return (
      <div className="scan">
        {!firstLaunch && errorMessage && modalShow &&
          <div className="book-details-modal">
            <div className="modal-content">
              <h2 className="model-h2"><span style={{ color: 'red' }}>Error</span></h2>
              <div className="added-book-thumbnail">{errorMessage}</div>
              <div className="edit-modal-buttons">
                <button className="search-button-6" onClick={handleModalExit}>Close</button>
              </div>
            </div>
          </div>
        }
        
        {!firstLaunch && !errorMessage && modalShow &&
          <div className="book-details-modal">
            <div className="modal-content">
              <h2 className="model-h2">Added <span style={{ color: 'green' }}>{addedBookTitle}</span></h2>
              <img className="added-book-thumbnail" src={addedBookThumbnailLink} alt={addedBookTitle} />
              <div className="edit-modal-buttons">
                <button className="search-button-6" onClick={handleModalExit}>Close</button>
              </div>
            </div>
          </div>
        }

        <form onSubmit={handleSubmit} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
          <InputMask 
            mask="999-9-9999-9999-9"
            maskPlaceholder=""
            placeholder="ISBN: 123-4-5678-9012-3"
            value={enteredISBN} 
            onChange={handleInputChange} 
            style={{
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              marginRight: '10px',
              width: '200px'
            }}
          />
          <button 
            type="submit"
            className="search-button"
          >
            Add
          </button>
        </form>
        {renderDropdowns()}

        <button className="search-button" onClick={toggleView}>{viewToggle ? 'Switch to Scan' : 'Switch to Scan'}</button>

      </div>
    );
  };

  const toggleView = () => {
    if(btnText === BTN_TXT.STOP){
      stopScan();
    }
    setViewToggle(!viewToggle);
  };

  return (
    <div>
      {viewToggle ? renderScan() : renderAddView()}
    </div>
  )
};
